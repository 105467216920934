import React from "react";
import DisclaimerContent from "../DisclaimerContent/DisclaimerContent";
const DisclaimerHero = () => {
  return (
    <div className="container-fluid">
      <div className="container-fluid disclaimer-hero">
      
      </div>
      <DisclaimerContent/>

    </div>
  );
};

export default DisclaimerHero;
